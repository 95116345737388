<template>
  <div>
    <h3 class="page-title">رول باشقۇرۇش</h3>
    <el-divider></el-divider>
    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
    </el-row>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
          :data="tableData" 
          border 
          style="width: 100%" 
          v-loading="loading">
          <el-table-column prop="name" label="رول نامى"></el-table-column>
          <el-table-column prop="description" label="ئىزاھات"></el-table-column>
          <el-table-column prop="status" width="120" label="ھالەت">
            <template slot-scope="scope">
              <label v-show="scope.row.status==1">ئوچۇق</label>
              <label v-show="scope.row.status==0">ئېتىك</label>
            </template>
          </el-table-column>
          <el-table-column label="مەشغۇلات" prop="dosome" width="300">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                v-if="pageTags.update"
                @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
              <el-button
                size="mini"
                type="danger"
                v-if="pageTags.delete"
                @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
              <el-button
                size="mini"
                type="warning"
                @click="setPermissions(scope.$index, scope.row)">ھوقۇق بېكىتىش</el-button>
            </template>
          </el-table-column>
      </el-table> 
    </div>

    <!-- el-dialog-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="رول نامى" prop="name">
            <el-input v-model="ruleForm.name" placeholder="رول نامى"></el-input>
          </el-form-item>
          <el-form-item label="ئىزاھات" prop="description">
            <el-input type="textarea" v-model="ruleForm.description" placeholder="ئىزاھات"></el-input>
          </el-form-item>
          <el-form-item label="ھالەت" prop="status" class="radio-form">
            <!-- <el-select v-model="ruleForm.status" placeholder="ھالەت">
              <el-option label="ئوچۇق" value="1"></el-option>
              <el-option label="ئېتىك" value="0"></el-option>
            </el-select> -->
            <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
            <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        ruleForm: {
          name: "",
          description: "",
          status: "1"
        },
        editIndex: -1,
        dialogTitle:"",
        addDialog:true,
        editId:"",
        pageTags:{}
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/roles").then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags")); 
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش ";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        console.log(row);
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش ";
        self.editId = row.id;
        self.ruleForm.name = row.name;
        self.ruleForm.description = row.description;
        self.ruleForm.status = row.status + '';
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/roles/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      setPermissions(index,row){
        self.$router.push({ path: 'permissions', query: { roleId : row.id,roleDescription : row.description }});
      },
      submitForm(formName) {
        if(self.addDialog){
          self.$post('admin/roles',{
            name:self.ruleForm.name,
            description:self.ruleForm.description,
            status:self.ruleForm.status
          }).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/roles/'+self.editId,{
            name:self.ruleForm.name,
            description:self.ruleForm.description,
            status:self.ruleForm.status
          }).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          name: "",
          description: "",
          status: "1"
        }
      },
      closeDialog(formName) {
        self.$refs[formName].resetFields();
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      }
    }
  };
</script>
